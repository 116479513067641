.nav {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.nav li:hover .subnav-wrap {
    display: block;
}

.nav>li>a {
    line-height: 60px;
    font-size: 16px;
    height: 60px;
    display: block;
    color: #000000;
}

.nav .li-logo {
    margin: 0 24px 0 0;
}

.nav .a-text {
    margin: 0 24px 0 6px;
}

.nav>li>a>img {
    height: 60px;
    padding: 14px 0;
}

.nav>li>i {
    color: #00b9f2;
    font-size: 16px;
}

.subnav>li>a {
    text-decoration: none;
    line-height: 60px;
    font-size: 14px;
}
.subnav>li>a>i {
    margin-right: 16px;
    font-size: 19px;
}

.subnav>li>i {
    padding: 12px;
}

.subnav>li {
    display: flex;
    justify-content: space-between;
    margin: 8px 0;
    transition: 0.5s;
}

.subnav li:hover {
    background: #d8d8d8;
}

.nav .subnav a {
    color: #000;
    padding: 0 16px;
    line-height: 38px;
}

.nav>li {
    display: inline-block;
}

.nav,
.subnav {
    list-style-type: none;
}

ul li {
    position: relative;
    cursor: pointer;
}

.nav .subnav {
    width: 280px;
    position: absolute;
    top: 80%;
    left: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}
.nav .subnav-wrap{
    display: none;
    position: absolute;
    top: 70%;
}
.nav .subnav-wrap>i{
    padding: 0;
    margin-left: 32px;
}
.nav .nav-arow-down {
    font-size: 13px;
}

.header {
    display: flex;
    justify-content: space-between;
    width: 1280px;
    height: 60px;
    background-color: #ffffff;
    padding: 0 16px;
    // position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
}

.container {
    width: 100%;
    display: flex;
    justify-content: center;
}

.search-box {
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-left: 24px;
}

.search-box:focus-within {
    border-color: #0e46cd;
    box-shadow: 0 0 5px #0e46cd;
}

.search-box .search-text {
    width: 200px;
    border: none;
    outline: none;
    background: none;
    padding: 10px 15px;
    font-size: 16px;
}

.search-box .search-btn {
    background: #fff;
    border: none;
    font-size: 20px;
    padding: 9px;
    color: #0e46cd;
    border-radius: 4px;
    cursor: pointer;
}
.cart{
    color: #0e46cd;
    font-size: 32px;
    margin: 8px 20px 0;
    transition: 0.5s, transform 0.5s;
}
.cart:hover{
    transform: scale(1.2);
}
.login-box{
    display: flex;
    align-items: center;
    height: 60px;
}
.login-box .login-btn{
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 48px;
    border: none;
    background-color: #0e46cd;
    color:#ffffff ;
    padding: 4px 12px;
    font-size: 14px;
    font-weight: 600;
    border-radius: 4px;
    cursor: pointer;
}

.login-box .login-btn:hover{
    box-shadow:0 0 5px 5px rgba(151, 176, 246, 0.3);
}

.login-box .login-btn i{
    font-size: 24px;
    margin-right: 8px;
}
.login-box .userName{
    color: #0e46cd;
    font-size: 16px;
    font-weight: 600;
    margin-right: 12px;
}