
.checkout-list-part {
    width: 1140px;
    margin: 0 auto;
    padding: 0;
    display: table;
    overflow: hidden;
    font-size: 16px;
    font-weight: 700;
}

.checkout-list-part li {
    display: table-cell;
    background: #50ad4e;
    color: #fff;
    height: 50px;
    line-height: 50px;
    padding-left: 30px;
    position: relative;
    list-style: none;
    padding: 0;
    text-align: -webkit-match-parent;
}

.checkout-triangle-right {
    right: -20px;
    z-index: 1;
    top: 0;
    border-top: 25px solid transparent;
    border-left: 20px solid #50ad4e;
    border-bottom: 25px solid transparent;
    position: absolute;
}

.checkout-triangle-left {
    border-top: 30px solid transparent;
    border-left: 24px solid #fff;
    border-bottom: 30px solid transparent;
    position: absolute;
    left: 0;
    top: -5px;
}

.checkout-list-part li:last-child {
    background: #e4e7eb;
    color: #272727;
    padding-right: 30px;
}

.checkout-span {
    margin-left: 36px;
}

////
.container-content {
    width: 1140px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
}

.payment-form {
    width: 70%;
    display: flex;
    flex-wrap: wrap;
    margin: 24px 0;

    h2 {
        margin: 24px 0 -8px;
        color: #50ad4e;
    }
}

.radio-input {
    display: inline-block;
    margin-right: 12px;
    cursor: pointer;
    transform: scale(2);
}

.method-label {
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 300px;
    height: 48px;
    cursor: pointer;
    font-size: 18px;
    font-weight: 600;
    padding: 14px 0;
    text-align: center;

    i {
        margin: 0 6px;
    }
}

.method-label:focus {
    border-color: #50ad4e;
}

.buyer-info {
    margin-top: 20px;
    width: 100%;
}

.info-row {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
}

.info-col {
    flex-basis: 33.33%;
    padding: 8px;

    h3 {
        margin-bottom: 8px;
    }
    input{
        margin-bottom: 8px;
    }
    span{
        color: red;
        margin: 0 12px;
    }
}

.info-input {
    width: 300px;
    height: 48px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
}

///
/// 
.wrap {
    width: 30%;
    border: 1px solid #ccc;
    border-radius: 8px;
    margin: 48px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 8px;
    h3 {
        font-size: 24px;
        margin: 0 12px -18px;

        span {
            margin: 0 8px;
            color: #50ad4e;
        }
    }

    ul {
        list-style: none;
        margin: 8px 8px;
    }

    li {
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        margin: 8px 8px;

        div{
            text-align: justify;
            padding: 4px;
            p{
                font-size: 15px;
                font-weight: 600;
                margin-right: 8px;
            }
            .price{
                color: #fb6a00;
            }
        }
    }

    .coupon {
        margin: 0 16px;
        border-top: 2px dashed #d1d1d1;
        display: flex;
        flex-direction: column;

        p {
            margin: 8px 0;
            font-size: 15px;
            text-align: justify;
        }

        .form-coupon input {
            width: 70%;
            height: 36px;
            font-size: 16px;
            padding: 12px;
            margin: 0 auto;
        }

        .form-coupon div {
            display: inline-block;
            margin-left: 8px;
            width: 27%;
            height: 36px;
            padding:8px;
            font-size: 16px;
            font-weight: 500;
            color: #fff;
            background: #50ad4e;
            opacity: 0.8;
            border: 1px solid#000000;
            border-radius: 4px;
        }

        .form-coupon div:hover {
            cursor: pointer;
            opacity: 1.2;
        }

        .total-coupon>ul>li {
            margin: 16px 0;
        }
    }
    .submit-pay{
        margin: 0 18px;
        height: 48px;
        font-size: 24px;
        font-weight: 600;
        color: #fff;
        background: #fb6a00;
        border-radius: 6px;
        opacity: 0.8;
    }
    .submit-pay:hover{
        cursor: pointer;
        opacity: 1;
    }
}