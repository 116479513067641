/* CSS for detailTop section */
.detailCourses-container {
    background: #fff;
}

.highlight {
    color: #fe9329;
}

.detailTop {
    background-color: #172b4d;
    height: 260px;
    display: flex;
    flex-direction: row;
}

.detail-top-left {
    padding: 20px;
    color: #fff;
    width: 800px;
    margin: 8px 96px;
}

.description-courses {
    padding: 20px;
}

.detail-top-left h1 {
    font-size: 28px;
    margin-bottom: 12px;
}

.detail-top-left p {
    font-size: 16px;
    margin: 8px 0px;
}

.detail-top-left>p>i {
    padding: 3px;
    color: #fe9329;
}

/* CSS for detail-top-right */
.detail-top-right {
    flex: 3;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    width: 400px;
    height: 468px;
    position: fixed;
    right: 20px;
    top: 122px;
}

.description {
    padding: 4px;
}

.description-image {
    bottom: 0;
    right: 0;
    height: 200px;
    background-size: cover;
    background-position: center;
    top: 0;
    left: 0;
}

.description-courses i {
    margin-left: -8px;
    margin-right: 8px;
}

.description-courses h1 {
    font-size: 24px;
    margin-bottom: 10px;
}

.description-courses p {
    font-size: 14px;
    margin: 8px;
}

.buy-btn {
    background-color: #fe9329;
    color: #fff;
    border: none;
    padding: 16px 141px;
    font-size: 24px;
}

.buy-btn:hover {
    cursor: pointer;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

//Courses Content
.course-content,
.knowledge,
.lecturers {
    margin-left: 112px;
    width: 800px;
    margin-top: 48px;
    margin-bottom: 48px;
}

.course-content .title {
    width: 112px;
    font-size: 20px;
    font-weight: bold;
    border: 1px solid #ccc;
    margin: 16px 4px 0px 64px;
    border-radius: 8px;
    padding: 8px 16px;
    color: #0e46cd;
    white-space: nowrap;
}

.course-content .title>i {
    margin-right: 4px;
}

.course {
    border: 1px solid #ccc;
    border-radius: 12px;
    padding: 12px;
    margin-top: -6px;
    background: #ffffff;
}

.course-item {
    display: block;
    padding: 8px;
}

.course-title {
    cursor: pointer;
    font-size: 18px;
    font-weight: bold;
    border: 2px solid #3d98ff;
    padding: 12px;
    border-radius: 8px;
    margin-bottom: 8px;
    transition: background-color 0.2s ease;
    color: #0e46cd;
    background-color: #f9f9f9;
}

.course-title:hover {
    background-color: #3d98ff;
    color: #fff;
}

.lessons {
    display: block;
    background: #f3f3f3;
    margin-top: -16px;
    border-radius: 0 0 8px 8px;
    padding: 8px;
}

.lesson {
    display: block;
    font-size: 16px;
    margin: 4px 8px;
    transition: background-color 0.2s ease;
    color: #333;
}

.lesson:hover {
    background-color: #ddd;
    border-radius: 4px;
}

//Học gì
.what-will-learn-block,.lecturers-wrap {
    margin: 20px 0;
    padding: 16px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.what-will-learn-details {
    text-align: justify;
    margin-bottom: 16px;
}
.what-will-learn-details p{
    margin-top: 8px;
}
.font-size18{
    font-size: 16px;
}
.see-more-content-details {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.btn-see-more-content {
    background-color: #3d98ff;
    color: #fff;
    border: none;
    padding: 8px 16px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 8px;
}

.btn-see-more-content:hover {
    background-color: #1f77ff;
}

//giảng viên
.lecturers-wrap{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 32px 0;
}
.lecturers-avt{
    margin: 0 16px;
}
.lecturers-img{
    width: 128px;
    border: 1px solid#ccc;
    border-radius: 50%;
    margin-bottom: 8px;
}
.lecturers-name{
    font-size: 14px;
    font-weight: 600;
    padding: 7px;
}
.lecturers-description{
    width: 600px;
    font-size: 16px;
    margin:0 16px;
}
.lecturers-description>p{
    margin: 8px 0;
}