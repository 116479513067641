.cart-container {
    width: 1200px;
    margin: 32px auto;
    padding: 16px;
}

.cart-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
}

.cart-left {
    display: flex;
    align-items: center;
}
.cart-left>i{
    font-size: 36px;
    border: 1px solid #50ad4e;
    border-radius: 50%;
    background: #50ad4e;
    color: #fff;
    padding: 16px 16px;
}

.cart-title {
    font-size: 24px;
    font-weight: bold;
    margin: 0;
}

.cart-description {
    display: flex;
    flex-direction: column;
    margin-left: 12px;
}
.cart-description div{
    display: flex;
    flex-direction: row;
}
.cart-description p {
    font-size: 16px;
    margin: 0;
}

.cart-right {
    display: flex;
    align-items: center;
}

.checkout-btn {
    text-decoration: none;
    background-color: #fe9329;
    color: #fff;
    border: none;
    padding: 12px 24px;
    font-size: 18px;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.checkout-btn:hover {
    background-color: #2975cc;
}

.cart-body{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}


.cart-content {
    display: flex;
    flex-direction: column;
}

.cart-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 0;
    border-bottom: 1px solid #ccc;
}

.cart-content-left {
    display: flex;
    align-items: center;
}

.cart-content-left img {
    width: 200px;
    height: 120px;
    object-fit: cover;
    border-radius: 8px;
    margin-right: 16px;
}


.description p {
    width:700px;
    font-size: 18px;
    font-weight: 600;
    margin: 0;
}

.description button {
    background: none;
    border: none;
    color: #858383;
    cursor: pointer;
    font-size: 14px;
    padding: 0;
    margin-top: 8px;
    align-items: start;
}
.description button:hover{
    color: #1d1d1d;
}
.description i{
    margin-right: 4px;
}
.cart-content-right {
    font-size: 18px;
    font-weight: bold;
}
.cart-content-right p{
    font-size: 24px;
    color: #fe9329;
}
.cart-item:last-child {
    border-bottom: none;
}