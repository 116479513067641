/* Courses.module.scss */

.courses-container {
    max-width: 1240px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
}
.courses-sales{
    display: flex;
    align-items: end;
    margin-bottom: 24px;
}
.courses-sales>h1{
    font-weight: 600;
    font-size: 48px ;
}
.courses-sales>p{
    font-weight: 600;
    font-size: 24px ;
    padding-bottom: 5px;
    margin: 0 3px;
}

.course-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
    margin-bottom: 20px;
}

.course-card {
    min-width: 256px;
    max-width: 256px;
    background-color: #fff;
    border: 1px solid #eeeded;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    margin: 4px 8px;
    overflow: hidden;
}
.course-card>a{
    color: #000;
    text-decoration: none;
}
.course-card:hover{
    cursor: pointer;
    box-shadow: 0 8px 8px rgba(0, 0, 0, 0.5);
    
}

.course-image {
    bottom: 0;
    right: 0;
    height: 131px;
    background-size: cover;
    background-position: center!important;
    top: 0;
    left: 0;
}
.content{
    margin: 4px 12px;
}
.content-top{
    height: 80px;
    line-height: 2;
}
.course-title {
    line-height: 1.5;
    font-size: 14px;
    font-weight: bold;
    margin-top: 10px;
    text-align: justify;
    text-justify: distribute-all-lines;
}

.course-instructor {
    font-size: 12px;
    color: #666;
}

.course-price {
    font-size: 18px;
    color: #fe9329;
    margin: 32px 0 12px;
    font-weight: 600;
}

.pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.page-link {
    padding: 8px 16px;
    margin: 0 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    color: #333;
    text-decoration: none;
    cursor: pointer;
}

.page-link.active {
    background-color: #e91e63;
    color: #fff;
}