.register-section{
  display: flex;
  justify-content: center;
}
.register-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;
  border-radius: 12px;
  max-height: 600px;
  min-height: 400px;
  width: 400px;
  min-width: 20%;
  background-color: rgb(218, 218, 218);
  text-align:center;
}
.form-register{
  width: 300px;
}
.register-title {
  font-weight: 600;
  font-size: 1.5rem;
}
.register-error{
  margin-bottom: 2px;
  align-self:center;
  color:rgb(255, 0, 55);
}
.form-register button {
  cursor: pointer;
  border: none;
  align-self: center;
  margin-top: 1rem;
  font-size: 1rem;
  padding: 0.65rem;
  border-radius: 4px;
  color: white;
  background-color: rgb(161, 161, 161);
}
.form-register button:hover{
  background-color: #131212;
}
.form-register input {
  border: none;
  padding: 0.35rem;
  font-family: "Inter", sans-serif;
  margin: 0.75rem 0;
  border-radius: 4px;
  width: 100%;
}