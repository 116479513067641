.cart-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    h1{
        margin-top: 32px;
    }
}


.checkout-list-part {
    width: 1140px;
    margin: 0 auto;
    padding: 0;
    display: table;
    overflow: hidden;
    font-size: 16px;
    font-weight: 700;
}

.checkout-list-part li {
    display: table-cell;
    background: #50ad4e;
    color: #fff;
    height: 50px;
    line-height: 50px;
    padding-left: 30px;
    position: relative;
    list-style: none;
    padding: 0;
    text-align: -webkit-match-parent;
}
.checkout-list-part li:last-child {
    background: #50ad4e;
    color: #fff;
    padding-right: 30px;
}
.checkout-span {
    margin-left: 36px;
}
.checkout-triangle-left {
    border-top: 30px solid transparent;
    border-left: 24px solid #fff;
    border-bottom: 30px solid transparent;
    position: absolute;
    left: 0;
    top: -5px;
}
.checkout-triangle-right {
    right: -20px;
    z-index: 1;
    top: 0;
    border-top: 25px solid transparent;
    border-left: 20px solid #50ad4e;
    border-bottom: 25px solid transparent;
    position: absolute;
}


.table {
    width: 1140px;
    border-collapse: collapse;
    border: 1px solid #ccc;
    margin: 24px 0;

}

th, td {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: left;
    font-size: 18px;
}

th {
    width: 250px;
    height: 28px;
    min-height: 28px;
    background-color: #f2f2f2;
}
td>li{
    list-style: none;
    margin: 4px 0;
}
.back-home>button{
    font-weight: 600;
    font-size: 32px;
    transition: 0.5s, transform 0.5s;
    padding: 4px;
}
.back-home>button:hover{
    transform: scale(1.2);
    cursor: pointer;
}