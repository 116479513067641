.container-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0;
    margin: 0;
}

.gallery-display-area {
    overflow: hidden;
    margin: 0 auto;
    padding: 0;
}

.gallery-wrap {
    width: 100%;
    animation: slideShow 10s infinite;
    white-space: nowrap;
    padding: 0;

}

.gallery-wrap .image {
    display: inline-block;
    vertical-align:baseline;
    -webkit-box-reflect: below 1px -webkit-gradient(linear,
            right top,
            right bottom,
            from(transparent),
            to(rgba(255, 255, 255, 0.3)));
}

.image img {
    max-width: 100%;
    padding: 0;
}

@keyframes slideShow {
    0%{
        margin-left: 0;
    }
    10%{
        margin-left: 0;
    }
    20%{
        margin-left: 0;
    }
    30%{
        margin-left: 0;
    }
    40%{
        margin-left: 0;
    }
    50%{
        margin-left: 0;
    }
    60%{
        margin-left: -100%;
    }
    70%{
        margin-left: -100%;
    }
    80%{
        margin-left: -100%;
    }
    90%{
        margin-left: -100%;
    }
    100%{
        margin-left: -100%;
    }
}