// Breadcrumb.module.scss

.breadcrumb-container {
    padding: 10px 0;
}

.breadcrumb-list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
}
.breadcrumb-list>i{
    margin-right: 4px;
}
.breadcrumb-item {
    display: flex;
    align-items: center;
}

.breadcrumb-link {
    color: #007bff;
    text-decoration: none;
    transition: color 0.2s;
}

.breadcrumb-link:hover {
    color: #0056b3;
}

.breadcrumb-separator {
    margin: 0 8px;
    color: #6c757d;
}

.breadcrumb-current {
    color: #6c757d;
    font-weight: 600;
}