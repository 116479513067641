.wrapbody-content {
    display: flex;
    justify-content: center;
}

.container-content {
    width: 1240px;
    height: 360px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.content-left {
    width: 238px;
    height: 300px;
}

.left-title,
.left-text,
.left-btn {
    width: 100%;
    text-decoration: none;
}

.left-title {
    margin-top: 24px;
    font-size: 24px;
    font-weight: 600;
    color: #172b4d;
}

.left-text {
    font-weight: 300 !important;
    margin: 24px 0;
    font-size: 14px;
    height: 42%;
    color: #172b4d;
}

.left-btn {
    display: block;
    width: 100px;
    padding: 8px;
    color: #0e46cd;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    border: 1px solid #0e46cd;
}

.left-btn:hover {
    box-shadow: 0 0 5px #0e46cd;

}

.content-right {
    max-width: 1002px;
    margin: 0 auto;
    padding: 20px;
    position: relative;
}

.gradient-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 166px;
}

.heading {
    /* Kiểu chữ và căn chỉnh tiêu đề */
    font-size: 24px;
    font-weight: bold;
    text-align: center;
}

.card-slider {
    /* Hiển thị slider với overflow-x: hidden */
    display: flex;
    overflow-x: hidden;
    /* Thay đổi thành hidden để ẩn thanh scroll ngang */
    scroll-behavior: smooth;
    margin-left: -8px;
}

.card {
    /* Các thẻ card trong slider */
    min-width: 256px;
    max-width: 256px;
    margin: 4px 8px;
    background-color: #ffffff;
    border: 0.5px solid #dae0e5;
    border-color: #dae0e5;
    border-radius: 4px;
    overflow: hidden;
    box-shadow: 0 2px 4px 0 rgba(83, 78, 78, 0.12);
    transition: 0.5s, transform 0.5s;
}
.card>a{
    text-decoration: none;
}
.card:hover {
    cursor: pointer;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.12);
    transform: scale(1.02);
}

.card-image {
    bottom: 0;
    right: 0;
    height: 126px;
    background-size: cover;
    background-position: center;
    top: 0;
    left: 0;
}

.card-content {
    height: 90px;
    padding: 10px;
}

.cardTitle {
    line-height: 1.5;
    color: #232323;
    font-size: 14px;
    font-weight: bold;
    text-align: justify;
    text-justify: distribute-all-lines;
    margin: 16px 16px 8px 16px;
}

.cardInstructor {
    /* Kiểu chữ và căn chỉnh thông tin người hướng dẫn */
    font-size: 12px;
    color: #4a4a4a;
    margin: 8px 16px;
}

.cardPrice {
    color: #fe9329;
    font-size: 18px;
    margin: 24px 16px 8px 16px;
}

.prev-btn,
.next-btn {
    /* CSS cho nút prev và next */
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: #ffffff;
    color: #0e46cd;
    border: 2px solid #f3f3f3;
    padding: 10px 16px;
    border-radius: 50%;
    box-shadow: 0 2px 4px 0 #dae0e5;
    cursor: pointer;
    font-size: 20px;
    outline: none;
    z-index: 1;
    /* Để đảm bảo nút không bị che bởi gradient-overlay */
}

.prev-btn {
    left: 0;
}

.prev-btn:hover i {
    font-size: 24px;
}

.next-btn {
    right: 0;
}

.next-btn:hover i {
    font-size: 24px;
}

.half-card {
    min-width: 128px;
    max-width: 128px;
    margin-right: 24px;
}

.hidden {
    display: none;
}
.card-nav-item > a{
    color: #007bff;
    text-decoration: none;
    width: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 1.5;
    align-items: center;
}
.card-nav {
    /* Các thẻ card trong slider */
    min-width: 256px;
    max-width: 256px;
    margin: 4px 8px;
    border: 0.5px solid #dae0e5;
    border-color: #dae0e5;
    border-radius: 4px;
    overflow: hidden;
    box-shadow: 0 2px 4px 0 rgba(83, 78, 78, 0.12);
    transition: 0.5s, transform 0.5s;
    font-size: 16px;
    color: #007bff;
    font-weight: 500;
    display: flex;
    justify-content: center;
}

.card-content-nav {
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 1.5;
    align-items: center;
}

//banner
/* Home.module.scss */

.baner {
    background: url('../../assets/image/bg/bg_moving_up.png') no-repeat center center;
    background-size: cover;
    background-clip: padding-box;
    padding: 32px 0;
    display: flex;
    justify-content: center;
}

.baner-bg {
    display: block;
    width: 800px;
    height: 350px;
    padding: 0;
    background: #ffffff;
    border: 0.1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 #000000;
}

.baner-content {
    background: url('../../assets/image/bg/ico_moving.png') no-repeat center center;
    background-size: 260px;
    padding: 200px;
    margin-left: 440px;
    position: absolute;
}

.baner-wrapper {
    position: relative;
}

.baner-title {
    text-align: center;
    margin: 24px 0;
    font-size: 32px;
    font-weight: 600;
}

.baner-ul {
    list-style: none;
    padding: 0;
    margin: 56px 32px;
}

.baner-text {
    font-size: 20px;
    font-weight: 400px;
    margin: 16px 16px;
}

.baner-text>i {
    color: #7ed321;
    transition: 0.5s, transform 0.2s;
    margin: 8px;
}

.baner-text>i:hover {
    color: #7ed321;
    transform: scale(1.4);
}