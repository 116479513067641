
.footer {
    background-color: #f7f7f7;
    padding: 40px 0;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

.separator {
    width: 1px;
    height: 180px;
    background-color: #ccc;
    margin: 0 48px;
}

.footer-content {
    display: flex;
    justify-content: space-between;
}

.footer-section {
    flex: 1;
    margin-right: 20px;
}

.footer-heading {
    font-size: 13px;
    font-weight: bold;
    margin: 10px 0;
    color: #172b4d;
}

.footer-text {
    font-size: 13px;
    line-height: 1.6;
    color: #172b4d;

}

.social-media-list {
    display: flex;
    list-style: none;
    padding: 0;
}

.social-media-item {
    margin-right: 10px;
}

.social-media-link-fb,.social-media-link-yt,.social-media-link-mt,.social-media-link-vs{
    font-size: 36px;
    text-decoration: none;
}
.social-media-link-fb{
    color: #475993;
}
.social-media-link-yt{
    color: #e02f2f;
}
.social-media-link-mt{
    color: #ffa100;
}
.social-media-link-vs{
    color: #00196a;
}


.footer-bottom {
    text-align: center;
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid #e5e5e5;
}