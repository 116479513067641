@import 'normalize.css';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;500;600&display=swap');

:root {
}

* {
    font-family: 'Roboto', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;

}

html {
    font-size: 62.5%;
    scroll-behavior: smooth;
}

body {
    color: #3d4246;
    font-size: 1.4rem;
    line-height: 1.15;
    text-rendering: optimizeSpeed;
}