.nav-category{
    height: 42px;
    display: flex;
    justify-content: center;
    background: #f6f6f6;
}
.subnav-category{
    height: 26px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    list-style-type: none;
    margin:0 -8px;
}
.subnav-category li{
    line-height: 42px;
    height: 100%;
}
.subnav-category li a{
    color: #2d5dd2;
    line-height: 26px;
    display: inline-block;
    height: 100%;
    text-decoration: none;
    margin: 0px 8px;
    padding: 0px 4px;
    border-radius: 5px;
    transition: 0.5s, transform 0.5s;
    font-size: 14px;
}
.subnav-category li:hover a{
    background: #b9ecff;
    transform: scale(1.2);
}
.subnav-category li a i{
    margin-right: 4px;
}